.transfer-summary {
    margin-top: 25px;

    &__details {
        margin-top: 32px;

        .withdrawal__amount {
            display: flex;
            justify-content: space-between;
            margin-bottom: 5px;
        }

        .beneficiary__details {
            &__items {
                display: flex;
                flex-direction: column;
                gap: 16px;

                .beneficiary__details__item {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                }
            }
        }
    }

    .btn__withdraw {
        width: 100%;
        margin-top: 137px;
    }
}