.stock-unit-input-field {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;

  .stock-unit-container {
    width: 100%;
    display: flex;
    align-items: center;
    height: 50px;
    border: 0.75px solid rgba(151, 151, 151, 0.8);
    border-radius: 4px;
    padding: 0 1em 2px 1em;
    gap: 10px;

    input {
      border: none;
      outline: none;
      // background: #f3f1fc;
      width: 100%;

      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
    }

    .unit-option {
      display: flex;
      align-items: center;
      border-left: 1px solid rgba(151, 151, 151, 0.8);
      padding-left: 10px;
      cursor: pointer;
    }
  }

  .stock-unit-list {
    display: none;
    // grid-template-columns: repeat(auto-fit, minmax(40px, 1fr));
    width: 100%;
    background-color: white;
    border-radius: 4px;
    position: absolute;
    top: 100%;
    margin-top: 8px;
    padding: 0;
    max-width: 100%;
    border: 0.75px solid rgba(151, 151, 151, 0.1);
    box-shadow: 0px 2px 2px rgba(151, 151, 151, 0.8);
    // padding: 10px 10px;
    gap: 10px;
    overflow: hidden;

    &.active {
      display: flex;
      flex-direction: column;
    }

    li {
      display: flex;
      align-items: center;
      // flex-direction: column;
      justify-content: space-between;
      font-size: 14px;
      transition: border 0.3s ease-in;
      position: relative;
      cursor: pointer;
      width: 100%;
      padding: 10px 10px;

      &:hover {
        color: white;
        background-color: #766df4;
        // &::before {
        //   content: "";
        //   display: block;
        //   width: 50%;
        //   height: 2px;
        //   position: relative;
        //   top: 100%;
        //   left: 0;
        //   right: 0;
        // }
      }

      &.selected {
        color: white;
        background-color: #766df4;
        // &::before {
        //   content: "";
        //   display: block;
        //   width: 50%;
        //   height: 2px;
        //   position: relative;
        //   top: 100%;
        //   left: 0;
        //   right: 0;
        //   margin-top: 2px;
        // }
      }
    }
  }

  .error-message {
    font-size: 13px;
    visibility: hidden;
    display: flex;
    margin-top: 2px;
    min-height: 15px;
    &.show {
      visibility: visible;
    }
  }
}
