@import "../../../assets/styles/base/variables";

  .add-customers{
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 33px 0 24px;
    &__close {
        text-align: right;
        img {
           height: 23px;
           width: 23px; 
        }
    }
    &__form {
        margin-top: 32px;
        gap: 16px;
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        &--gender {
            gap: 16px;
        }
    }
    &__button {
        width: 100%;
    }
  }
// }
.boxed-radio  {
    background: #F3F1FC;
    border: 0.75px solid rgba(151, 151, 151, 0.3);
    border-radius: 4px;
    height: 59px;
}
.date-input input {
    background: #F3F1FC;
}