@import "../../../assets/styles/base/variables";

.salesTransactionSummary {
  display: flex;
  flex-direction: column;
  min-height: 95vh;
  p {
    font-size: 14px;
  }
  .orderDetails {
    display: flex;
    gap: 1%;
    justify-content: space-between;
    margin-bottom: 10px;
    p {
      overflow: hidden;
      width: 24%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  &__bottom {
    padding: 20px 0;
    display: flex;
    justify-content: space-between;
    gap: 20px;
    margin-top: auto;
  }
}
