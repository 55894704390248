@import "../../../assets/styles/base/variables";

.terminals {
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__tab {
    display: flex;
    align-items: center;
    margin: 20px 0;
    border-bottom: 1px solid #e8e9eb;
    overflow: auto;

    &__item {
      transition: all 0.2s ease-in-out;
      font-size: 16px;
      text-decoration: none !important;
      padding: 0.5em 0;
      color: #696677;
      border-bottom: 2px solid transparent;
      min-width: fit-content;
      margin-right: 2rem;

      &.active {
        color: $purple-color;
        border-color: $purple-color;
      }
    }
  }

  &__tables {
    margin-top: 34px;
    width: 100%;
    background-color: $white-color;
    border-radius: 16px 16px 0px 0px;
    padding: 24px;
    min-height: 281px;
  }
}
