@import "../../../../assets/styles/base/variables";

.transfer-payment {
  .refresh-btn {
    height: 40px;
    padding: 0 15px;
  }
  &__transactions {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 30px;
    height: 300px;
    overflow-y: auto;

    .transaction-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border: 1px solid #ddd;
      border-radius: 8px;
      padding: 10px 15px;
      cursor: pointer;
      gap: 30px;

      .checked-item {
        min-width: 25px;
        min-height: 25px;
        border: 1px solid #ddd;
        border-radius: 50%;
        margin-right: 15px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: $white-color;
      }

      &.active {
        border-color: $purple-color;
        .checked-item {
          background: $purple-color;
          border-color: $purple-color;
        }
      }
    }
  }
  &__bottom {
    position: absolute;
    bottom: 0px;
    padding: 20px 0;
    width: 420px;
    background: $white-color;
  }
}
