@import "../../../assets/styles/base/variables";

.restock-product-widget {
  padding-bottom: 42px;
  display: flex;
  flex-direction: column;
  height: 100%;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 42px 0;

    button {
      background: none;
      outline: none;
      border: none;
    }
  }

  &__content {
    margin-top: 32px;
    padding: 0 6px;

    .product-restock-form {
      display: grid;
      grid-template-columns: repeat(6, 1fr);
      align-items: center;
      gap: 32px;
      width: 100%;
      padding: 16px;
      border: 1px solid rgba(151, 151, 151, 0.3);
      border-radius: 12px;

      .product-restock-variant-section {
        margin-top: 16px;
      }
    }

    .profit-margin-input {
      // flex-shrink: 0;
      margin-bottom: 18px;
      width: 100%;
    }

    .profit_margin_container {
      height: 50px;
      border: 0.75px solid rgba(151, 151, 151, 0.8);
      border-radius: 4px;
      padding: 0 1em 2px 1em;
      // background: #f3f1fc;
      width: 100%;
      outline: none;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      // margin-bottom: 20px;
    }

    .product-restock-variant-list-section {
      display: flex;
      flex-direction: column;
      // align-items: center;
      padding: 16px;
      gap: 24px;

      // margin-top: 71px;
      // margin-bottom: 166px;
      min-height: 324px;

      border: 1px solid rgba(151, 151, 151, 0.3);
      border-radius: 12px;

      .profit-margin-input {
        // flex-shrink: 0;
        margin-bottom: 18px;
        width: 100%;
      }

      .profit_margin_container {
        height: 50px;
        border: 0.75px solid rgba(151, 151, 151, 0.8);
        border-radius: 4px;
        padding: 0 1em 2px 1em;
        // background: #f3f1fc;
        width: 100%;
        outline: none;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        // margin-bottom: 20px;
      }

      &__item {
        display: flex;
        gap: 32px;

        &:nth-child(odd) {
          border-bottom: 0.75px solid rgba(151, 151, 151, 0.3);
        }

        .variant-delete-icon {
          background: none;
          outline: none;
          border: none;
          align-self: flex-start;
          margin-left: 10px;
          margin-top: 10px;
        }
      }

      .add-variant-button {
        background: none;
        outline: none;
        border: none;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-inline: auto;
        font-size: 12px;
        gap: 12px;
        color: $purple-color;
      }
    }
  }
}
