.edit-product-category {
    padding: 24px;

    .modal-buttons {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 30px;
    }
}
