@import "../../../../assets/styles/base/variables";
@import "../../../../assets/styles/base/mixins";

.add-multiple-products-page {
  background-color: $white-color;
  padding: 5em 32px;
  border-radius: 8px;

  .back__btn {
    margin-top: 48px;
    min-width: 160px;
  }
}
