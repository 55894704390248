.bank-verification-otp-widget {
    background-color: white;
    box-shadow: 0px 0px 4px 0px #00000024;
    padding: 32px;

    &__field {
        margin-top: 24px;

        .otp-input__field {
            & > div {
                width: 50%;
                justify-content: center;
                // margin: 0 auto;
            }
        }
    }

    &__actions {
        margin-top: 38px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .resend-otp__btn {
            cursor: pointer;
            user-select: none;
        }
    }
}
