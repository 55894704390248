@import "../../../../assets/styles/base/variables";
@import "../../../../assets/styles/base/mixins";

.all-transfers {
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 30px;
    flex-wrap: wrap;
  }

  &__tab {
    display: flex;
    align-items: center;
    margin: 20px 0;
    border-bottom: 1px solid #e8e9eb;
    overflow: auto;

    &__item {
      transition: all 0.2s ease-in-out;
      font-size: 16px;
      text-decoration: none !important;
      padding: 0.5em 0;
      color: #696677;
      border-bottom: 2px solid transparent;
      min-width: fit-content;
      margin-right: 2rem;

      &.active {
        color: $purple-color;
        border-color: $purple-color;
      }

      .transfer-alert {
        background: $danger-color;
        color: $white-color;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        font-size: 10px;
        border-radius: 50%;
        min-width: 20px;
        min-height: 20px;
        max-width: 20px;
        max-height: 20px;
      }
    }
  }
}
