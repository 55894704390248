.product-categories-page {
    &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    &__desc {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 25px;
    }
}
