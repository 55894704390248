@import "../../../assets/styles/base/mixins";
@import "../../../assets/styles/base/variables";

.stores-transaction {
  padding-top: 1.5em;

  @include respond-to("big-tablet") {
    padding-top: 1.5em;
  }

  &__tab {
    &__menu {
      display: flex;
      align-items: center;
      margin-bottom: 2em;
      border-bottom: 1px solid #e8e9eb;
      margin-top: 1.5em;
      overflow: auto;

      & > * + * {
        margin-left: 2rem;
        @include respond-to("tablet") {
          margin-left: 15px;
        }
      }

      &__item {
        display: flex;
        align-items: center;
        transition: all 0.2s ease-in-out;
        font-size: 16px;
        text-decoration: none !important;
        padding: 0.5em 0;
        color: #696677;
        border-bottom: 2px solid transparent;
        min-width: fit-content;

        &.active {
          color: $purple-color;
          border-color: $purple-color;
        }
      }
    }
  }
}
