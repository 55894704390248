@import "../../../assets/styles/base/variables";
@import "../../../assets/styles/base/mixins";

.shop-details {
  background-color: $white-color;
  box-shadow: 0px 0px 4px 0px #00000024;
  border-radius: 12px;
  padding: 48px 32px;
  max-width: 464px;
}
