@import "../../../assets/styles/base/variables";
@import "../../../assets/styles/base/mixins";

.tags-table {
  margin-top: 33px;
  width: 100%;
  background-color: $white-color;
  border-radius: 16px 16px 0px 0px;
  padding: 24px 0;
  min-height: 500px;

  &__header {
    display: flex;
    align-items: center;
    padding: 0 24px 24px 24px;
    border-bottom: 1px solid #edf2f7;
    gap: 1.5em;

    @include respond-to("tablet") {
      padding: 0 10px 10px 10px;
      gap: 15px;
      flex-wrap: wrap;
    }

    .search-section {
      width: 700px;

      @include respond-to("large") {
        flex: none;
        width: 100%;
      }
    }
  }

  table {
    width: 100%;
    border-collapse: collapse;

    .table__action {
      outline: none;
      background: none;
      border: none;
      font-weight: 500;

      &.edit {
        color: $purple-color;
      }

      &.delete {
        color: $danger-color;
      }
    }

    tbody {
      tr {
        display: flex;
        align-items: center;
        // justify-content: space-between;
        padding: 16px;
        gap: 24px;
        box-shadow: 0px 0px 3px 0px #00000015;
        width: 100%;

        td {
          &:nth-child(2) {
            margin-left: auto;
          }

          .tags__btn {
            height: 28px;
            width: min-content;
            padding: 10px;
            font-size: 12px;
          }
        }
      }
    }
  }
}
