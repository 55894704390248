@import "../../../assets/styles/base/mixins";
@import "../../../assets/styles/base/variables";

.stores-table {
  margin-top: 32px;
  background-color: $white-color;
  min-height: fit-content;
  padding: 24px;

  .detail__btn {
    font-size: 12px;
    padding: 10px 16px;
    border-radius: 8px;
    color: white;
    height: auto;
    background-color: $purple-color;
  }

  &__header {
    display: flex;
    gap: 16px;
    margin-bottom: 24px;
    width: 90%;

    &__search-section {
      flex: 1;
    }
  }

  &__content {
    table {
      border-collapse: collapse;
      background-color: $white-color;
      width: 100%;

      @include respond-to("big-tablet") {
        display: none;
      }

      th,
      td {
        padding: 10px 0px;
        font-size: 12px;
        text-align: left;
        min-width: 150px;
        max-width: 140px;

        &:first-child {
          max-width: 150px;
        }
        &:last-child {
          padding-right: 24px;
        }
      }

      tr {
        border-bottom: 1px solid #edf2f7;
      }

      thead {
        th {
          color: #696677;
        }
      }

      tbody {
        tr {
          position: relative;

          &:last-child {
            border-bottom: none;
          }
        }

        td {
          color: $dark-color;
          padding: 12px 0;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          &:last-child {
            overflow: visible;
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
      }
    }

    &-mobile {
      display: none;

      @include respond-to("big-tablet") {
        display: block;
        display: flex;
        flex-direction: column;
        gap: 20px;
      }

      &-card {
        display: flex;
        flex-direction: column;
        gap: 10px;
        border-radius: 8px;
        padding: 12px;
        background-color: $white-color;
        border: 1px solid #ddd;

        &__title {
          display: flex;
          gap: 40px;
          justify-content: space-between;
          align-items: center;
        }

        .status {
          color: #19ae57;
          background-color: #e6fff0;
          border-radius: 8px;
          padding: 5px 16px;
        }

        .view-details {
          margin-top: 20px;
        }
      }
    }
  }

  &__no-product {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 35px 0px;
    margin: 0 auto;
    max-width: 360px;

    &-content {
      max-width: 340px;
      margin-top: 32px;
    }
  }
}
