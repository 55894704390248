@import "../../../assets/styles/base/variables";
@import "../../../assets/styles/base/mixins";

.side-modal {
  width: 100%;
  height: 100%;
  position: fixed;
  right: 0;
  left: 0;
  top: 0;
  margin: auto;
  bottom: 0;
  display: flex;
  align-items: center;
  z-index: 1000;

  &.positionRight {
    justify-content: right;
  }
  &.positionLeft {
    justify-content: left;
  }

  @include respond-to("tablet") {
    align-items: flex-end;
  }

  &__container {
    background-color: $white-color;
    height: 100%;
    overflow: auto;
    width: 500px;
    padding: 10px 40px;
    z-index: 2;
  }

  &__backdrop {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 1;
    overflow: hidden;
  }
}
