@import "../../../assets/styles/base/variables";
@import "../../../assets/styles/base/mixins";

.edit-giftcard {
  &__content {
    background-color: $white-color;
    padding: 32px;
    border-radius: 8px;
    min-height: 500px;

    @include respond-to("mobile") {
      padding: 34px 15px;
    }

    &__body {
      // padding-top: 30px;
    }
  }
}
