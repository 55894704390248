@import "../../../assets/styles/base/mixins";
@import "../../../assets/styles/base/variables";

.add-single-product {
  background: #ffffff;

  &-form {
    margin-top: 24px;
    min-height: 600px;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.14);
    border-radius: 12px;
    padding: 48px 33px;
    max-width: 1150px;

    @include respond-to("mobile") {
      width: 100%;
      max-width: 100%;
      padding: 20px;
    }

    .form-section {
      width: 100%;
      display: flex;
      gap: 112px;

      @include respond-to("mobile") {
        flex-direction: column-reverse;
        width: 100%;
        gap: 50px;
      }
    }

    .profit-margin-input {
      // flex-shrink: 0;
      margin-bottom: 18px;
      width: 100%;
      max-width: 100%;
      white-space: nowrap;
      overflow: hidden;
    }

    .profit_margin_container {
      height: 50px;
      border: 0.75px solid rgba(151, 151, 151, 0.8);
      border-radius: 4px;
      padding: 0 1em 2px 1em;
      // background: #f3f1fc;
      width: 100%;
      outline: none;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      // margin-bottom: 20px;
    }

    .form-section-two {
      margin-top: 32px;
      display: flex;
      align-items: center;
      gap: 32px;
      padding: 16px;
      border: 1px solid rgba(151, 151, 151, 0.3);
      border-radius: 12px;

      @include respond-to("mobile") {
        width: 100%;
        flex-direction: column;
      }

      &:disabled {
        opacity: 0.5;
      }
    }

    .product-image-section {
      padding: 20px;
      width: 266px;
      // height: 278px;
      background: #ffffff;
      box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.14);
      border-radius: 12px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 30px;

      .product-image-container {
        width: 218px;
        height: 166px;
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          max-width: 100%;
          height: 100%;
          display: block;
        }
      }

      .upload-button {
        height: 32px;
        font-size: 12px;
      }
    }

    .product-variants-section {
      margin-top: 64px;

      .product-variants-list {
        border: 1px solid rgba(151, 151, 151, 0.3);
        border-radius: 12px;
        padding: 16px;
        display: flex;
        flex-direction: column;
        gap: 24px;

        &__item {
          display: flex;
          gap: 32px;

          @include respond-to("small") {
            flex-wrap: wrap;
          }

          @include respond-to("big-tablet") {
            flex-wrap: wrap;
          }

          @include respond-to("mobile") {
            flex-direction: column;
            width: 100%;
          }

          &:nth-child(odd) {
            border-bottom: 0.75px solid rgba(151, 151, 151, 0.3);
          }

          .variant-delete-icon {
            background: none;
            outline: none;
            border: none;
            align-self: flex-start;
            margin-left: 10px;
            margin-top: 10px;
          }
        }

        .add-variant-button {
          background: none;
          outline: none;
          border: none;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-inline: auto;
          font-size: 12px;
          gap: 12px;
          color: $purple-color;
        }
      }
    }
  }

  &__actions {
    margin-top: 72px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 1090px;
  }
}
