@import "../../../assets/styles/base/variables";
@import "../../../assets/styles/base/mixins";

.store-products-page {
  display: flex;
  width: 100%;
  gap: 24px;
  padding: 20px 20px;

  .products-list {
    flex: auto;
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  .cart-section {
    position: sticky;
    height: 100vh;
    width: 400px;
    top: 20px;
    margin-top: -150px;
    z-index: 2;
  }
}
