.account-verification-widget {
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 40px;
    width: 900px;
    position: relative;
    box-shadow: -4px 8px 24px 0px #372c5805;
    border-radius: 8px;
    overflow: hidden;

    @media (max-width: 768px) {
        width: 100%;
        padding: 25px;
    }

    .btn__dashboard {
        width: 191px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__image {
        position: absolute;
        right: 0;
        top: 0;

        @media (max-width: 768px) {
            width: 7rem;
        }
    }
}
