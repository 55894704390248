@import "../../../assets/styles/base/variables";

.cart-items-widget {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  display: flex;
  justify-content: right;
  align-items: center;
  z-index: 1000;

  &__backdrop {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 1;
    overflow: hidden;
  }

  &__content {
    background-color: $white-color;
    height: 100%;
    overflow: auto;
    width: 498px;
    padding: 10px 40px;
    z-index: 100;
  }
}
