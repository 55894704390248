.account-settings-page {
    &__nav {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .btn__save-preferences {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 99px;
            font-weight: bold;
        }
    }

    &__content {
        margin: 2em 0;

        &__section {
            margin-bottom: 2em;

            .payment__methods {
                display: flex;
                align-items: center;
                justify-content: space-between;

                .payment__options {
                    display: flex;
                    align-items: center;
                    flex-basis: 30%;

                    .payment__option {
                        display: flex;
                        align-items: center;
                    }
                }
            }
        }
    }
}