@import "../../../../assets/styles/base/mixins";
@import "../../../../assets/styles/base/variables";

.all-transactions {
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 30px;
    flex-wrap: wrap;
  }

  &__accounts {
    margin: 25px 0 20px 0;
    display: flex;
    max-width: 500px;
    padding: 10px;
    border-radius: 12px;
    background: $white-color;
    width: max-content;
    overflow: auto;
    .account {
      height: 45px;
      width: 180px;
      background: #f3f1fc;
      border-radius: 12px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #696677;
      margin-right: 10px;
      cursor: pointer;
      &:last-child {
        margin-right: 0;
      }
      &.active {
        background: $purple-color;
        color: $white-color;
      }
    }
  }
}
