@import "../../../../assets/styles/base/variables";
@import "../../../../assets/styles/base/mixins";

.add-products-page {
  background-color: #ffffff;
  padding: 28px 32px;
  width: 100%;
  border-radius: 8px;
  min-height: 600px;

  @include respond-to("mobile") {
    padding: 28px 16px;
  }

  &__content {
    max-width: 600px;
    margin: 0 auto;

    &__body {
      margin-top: 50px;
    }
  }
}
