@import "../../../assets/styles/base/variables";
@import "../../../assets/styles/base/mixins";

.campaign-form {
  &__fields {
    margin-top: 24px;
    &-group {
      display: flex;
      gap: 24px;
      @include respond-to("big-tablet") {
        gap: 0px;
        flex-wrap: wrap;
      }

      & > div {
        width: 50%;
        @include respond-to("big-tablet") {
          width: 100%;
        }
      }
    }
  }

  &__buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 48px;
  }
}
