@import "../../../../assets//styles/base/variables";
@import "../../../../assets//styles/base/mixins";

.add-single-product-page {
  padding: 24px;
  border-radius: 8px;

  .back__btn {
    margin-top: 4em;
    margin-bottom: 2em;
  }

  @include respond-to("mobile") {
    // padding: 20px;
  }

  &__content {
    margin-top: 24px;
    padding: 24px;
    background-color: $white-color;
  }
}
