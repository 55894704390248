@import "../../../assets/styles/base/variables";
@import "../../../assets/styles/base/typography";

.customer-transactions__table {
  background-color: $white-color;
  padding: 24px;
  border-radius: 16px 16px 0px 0px;
  width: 100%;

  table {
    border-collapse: collapse;
    min-width: 100%;
    background-color: $white-color;
    border-radius: 16px;
    overflow: hidden;

    thead {
      tr {
        text-align: left;
        box-shadow: 0px 1px 0px #edf2f7;
        color: #696677;
        font-weight: 300;
      }
    }

    th,
    td {
      padding: 18px 0;
      font-size: 12px;
    }

    td {
      span {
        font-size: 12px;
      }
    }

    th {
      color: #696677;
    }
  }
}
.green--text {
  color: #19AE57;
}
