@import "../../../assets/styles/base/variables";
@import "../../../assets/styles/base/mixins";

.restock-single-product {
  padding: 5em 0;

  &__content {
    margin-top: 56px;

    &__container {
      display: flex;
      flex-direction: column;
      gap: 8px;

      .product-details {
        background-color: $white-color;
        border-radius: 12px 12px 0px 0px;
        padding: 24px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 16px;

        &__container {
          display: flex;
          align-items: center;
          gap: 77px;
        }

        &__actions {
          display: flex;
          align-items: center;
          gap: 24px;
        }
      }

      .product-restock-table {
        background-color: $white-color;
        border-radius: 0px 0px 12px 12px;
        padding: 24px;
        min-height: 248px;

        table {
          border-collapse: collapse;
          background-color: $white-color;
          width: 100%;

          th,
          td {
            padding: 10px 0px;
            font-size: 12px;
            text-align: left;
            min-width: 80px;
            max-width: 140px;

            &:first-child {
              max-width: 150px;
              // padding-left: 24px;
            }
            &:last-child {
              padding-right: 24px;
            }
          }

          tr {
            border-bottom: 1px solid #edf2f7;
          }

          thead {
            th {
              color: #696677;
            }
          }

          tbody {
            tr {
              position: relative;

              &:last-child {
                border-bottom: none;
              }
            }

            td {
              color: $dark-color;
              padding: 16px 0;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              &:last-child {
                overflow: visible;
                // display: flex;
                // justify-content: center;
                // align-items: center;
              }
            }
          }
        }
      }
    }
  }
}
