@import "../../../assets/styles/base/mixins";
@import "../../../assets/styles/base/variables";

.stock-history-table {
  background-color: $white-color;

  &__content {
    padding: 10px 24px 30px 24px;
    width: 100%;
    overflow-x: auto;
    min-height: 500px;

    table {
      border-collapse: collapse;
      background-color: $white-color;
      width: 100%;

      th,
      td {
        padding: 10px 0px;
        font-size: 12px;
        text-align: left;
        min-width: 80px;
        max-width: 140px;

        &:first-child {
          max-width: 150px;
          // padding-left: 24px;
        }
        &:last-child {
          padding-right: 24px;
        }
      }

      tr {
        border-bottom: 1px solid #edf2f7;
      }

      thead {
        th {
          color: #696677;
        }
      }

      tbody {
        tr {
          position: relative;

          &:last-child {
            border-bottom: none;
          }
        }

        td {
          color: $dark-color;
          padding: 16px 0;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          &:last-child {
            overflow: visible;
            // display: flex;
            // justify-content: center;
            // align-items: center;
          }
        }
      }
    }
  }
}
