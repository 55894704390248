@import "../../../assets/styles/base/variables";
@import "../../../assets/styles/base/mixins";

.dashboard {
  width: 100%;
  height: 100%;

  &__nav {
    width: calc(100% + 64px);
    height: 72px;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0 2em;
    margin-left: -32px;

    @include respond-to("small") {
      display: none;
    }

    &__verify__btn {
      background-color: $purple-color;
      height: 40px;
      color: $white-color;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 32px;
      padding: 16px 24px;

      &:hover {
        color: $white-color;
        text-decoration: none;
      }

      &.verified {
        background-color: #e8f7ee;
        color: #19ae57;
      }

      &.unverified {
        background-color: $purple-color;
        color: $white-color;
      }

      &.pending_review {
        background-color: #f7ad3c;
        color: $white-color;
      }
    }
  }

  &__content {
    padding: 25px 0;

    &__actions {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
      gap: 15px;

      .title {
        @include respond-to("tablet") {
          margin-left: auto;
        }
      }

      &__items {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        margin-left: auto;
      }
    }

    .sub-accounts {
      @include respond-to("tablet") {
        margin: 5px 0;
      }
    }

    &__tab {
      display: flex;
      align-items: center;
      margin: 15px 0 0;
      border-bottom: 1px solid #e8e9eb;
      overflow: auto;

      &__item {
        transition: all 0.2s ease-in-out;
        font-size: 16px;
        text-decoration: none !important;
        padding: 0.5em 0;
        color: #696677;
        border-bottom: 2px solid transparent;
        min-width: fit-content;
        margin-right: 2rem;

        &.active {
          color: $purple-color;
          border-color: $purple-color;
        }
      }
    }

    &__accounts__menu {
      margin-left: auto;
      max-width: 20%;
    }
  }
}
