.customers-page-nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.customer-buttons {
    gap: 16px;
    .btn {
        width: 220px;
    }
}

