@import "../../../assets/styles/base/variables";
@import "../../../assets/styles/base/mixins";

.users-table {
  width: 100%;
  overflow: auto;

  table {
    border-collapse: collapse;
    background-color: $white-color;
    width: 100%;

    @include respond-to("tablet") {
      display: none;
    }

    th,
    td {
      padding: 10px 24px;
      font-size: 12px;
      text-align: left;
      min-width: 140px;
      max-width: 200px;

      &:first-child {
        min-width: 50px;
      }
    }

    tr {
      border-bottom: 1px solid #edf2f7;
    }

    thead {
      th {
        color: #696677;
      }
    }

    tbody {
      tr {
        &:last-child {
          border-bottom: none;
        }
      }

      td {
        color: $dark-color;
        padding: 12px 24px;

        &:last-child {
          padding: 12px 30px;
          display: flex;
          align-items: center;

          span {
            cursor: pointer;
            font-size: 25px;
            transform: rotate(90deg);
            display: block;
            width: max-content;
            color: $gray-color;
          }
        }
      }
    }
  }

  .mobile-table {
    background: $white-color;
    display: none;

    @include respond-to("tablet") {
      display: block;
    }

    &__row {
      padding: 10px 10px;
      border-bottom: 1px solid #ddd;
    }

    .row-item {
      display: flex;
      gap: 20px;
      justify-content: space-between;
      margin-bottom: 8px;
      &:last-child {
        margin-bottom: 0;
      }

      &__value {
        font-size: 12px;
      }

      &__title {
        margin-bottom: 0;
        font-weight: normal;
        font-size: 12px;
      }
    }
  }
}
