.stock-history {
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  &__content {
    margin-top: 32px;
  }
}
