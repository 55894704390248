@import "../../../../assets/styles/base/variables";
@import "../../../../assets/styles/base/mixins";

.all-campaigns {
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__nav {
    display: flex;
    border-bottom: 1px solid #e8e9eb;
    margin-bottom: 30px;

    &__item {
      display: flex;
      align-items: center;
      transition: all 0.2s ease-in-out;
      font-size: 16px;
      text-decoration: none !important;
      padding: 0.5em 0;
      color: #696677;
      border-bottom: 2px solid transparent;
      min-width: fit-content;
      margin-right: 2rem;
      @include respond-to("tablet") {
        margin-right: 15px;
      }

      &.active {
        color: $purple-color;
        border-color: $purple-color;
      }
    }
  }
}
