@import "../../../assets/styles/base/mixins";
@import "../../../assets/styles/base/variables";

.product-category-table {
  margin-top: 32px;
  background-color: $white-color;
  min-height: 600px;
  padding-top: 20px;

  &__header {
    display: flex;
    align-items: center;
    gap: 16px;
    padding: 24px;

    @include respond-to("tablet") {
      flex-direction: column;
      align-items: flex-end;
      gap: 5px;
    }

    &__search__section {
      // flex: 1;
      min-width: 250px;
      width: 700px;

      // @include respond-to("large") {
      //     // flex: none;
      //     width: 100%;
      // }
    }
  }

  .empty__state {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 0 auto;

    img {
      margin-top: 100px;
    }
  }

  &__content {
    padding: 0 24px;

    table {
      border-collapse: collapse;
      background-color: $white-color;
      width: 100%;

      @include respond-to("large") {
        display: none;
      }

      th,
      td {
        padding: 10px 0px;
        font-size: 12px;
        text-align: left;
        min-width: 80px;
        max-width: 140px;

        &:first-child {
          max-width: 150px;
          // padding-left: 24px;
        }
        &:last-child {
          padding-right: 24px;
        }
      }

      tr {
        border-bottom: 1px solid #edf2f7;
      }

      thead {
        th {
          color: #696677;
        }
      }

      tbody {
        tr {
          position: relative;

          &:last-child {
            border-bottom: none;
          }
        }

        td {
          color: $dark-color;
          padding: 12px 0;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          &:last-child {
            overflow: visible;
            // display: flex;
            // justify-content: center;
            // align-items: center;
          }
        }
      }
    }

    &-mobile {
      display: none;

      @include respond-to("large") {
        display: flex;
        flex-direction: column;
        gap: 20px;
      }

      &-card {
        display: flex;
        flex-direction: column;
        gap: 10px;
        border-radius: 8px;
        padding: 12px;
        background-color: $white-color;
        border: 1px solid #ddd;

        &__title {
          display: flex;
          gap: 40px;
          justify-content: space-between;
          align-items: center;
        }

        &__next {
          display: flex;
          justify-content: space-between;
          margin-top: 20px;

          @include respond-to("mobile") {
            display: flex;
            flex-direction: column;
            gap: 10px;
          }
        }

        .status {
          color: #19ae57;
          background-color: #e6fff0;
          border-radius: 8px;
          padding: 5px 16px;
        }
      }
    }
  }
}
