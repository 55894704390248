@import "../../../assets/styles/base/variables";

.custom-dropdown {
  &__select {
    width: 100%;

    &__control {
      min-height: 50px !important;
      display: flex;
      align-items: center;
      //box-shadow: none !important;
      border: 1px solid #979797 !important;
    }

    &__indicators {
      // background-color: red !important;
      .custom-dropdown__select__indicator-separator {
        display: none !important;
      }
    }

    &__input {
      outline: none !important;
    }
  }

  .error-message {
    font-size: 13px;
    visibility: hidden;
    display: flex;
    margin-top: 2px;
    min-height: 15px;

    &.show {
      visibility: visible;
    }
  }
}
