.edit-subaccount__modal {
    padding: 16px;

    &__form {
        margin-top: 32px;
    }

    &__actions {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 64px;
    }
}
