@import "../../../assets/styles/base/variables";
@import "../../../assets/styles/base/mixins";

.customers-table {
  margin-top: 32px;
  @include respond-to("tablet") {
    margin-top: 15px;
  }
  &__header {
    border-bottom: 1px solid #e8e9eb;
    display: flex;
    align-items: center;
    gap: 48px;

    &-item {
      padding-bottom: 16px;
      cursor: pointer;
      position: relative;

      &.active {
        color: $purple-color;

        &::before {
          content: "";
          display: inline-block;
          height: 4px;
          width: 56px;
          background-color: $purple-color;
          position: absolute;
          bottom: 0;
          left: 0;
          border-radius: 1px 1px 0px 0px;
        }
      }
    }
  }

  &__content {
    background-color: $white-color;
    padding: 24px 0;
    border-radius: 16px;
    width: 100%;

    &__header {
      display: flex;
      align-items: center;
      padding: 0 24px 24px 24px;
      border-bottom: 1px solid #edf2f7;
      gap: 1.5em;

      @include respond-to("tablet") {
        padding: 0 10px 10px 10px;
        gap: 15px;
        flex-wrap: wrap;
      }
    }

    .table-section {
      width: 100%;
      overflow: auto;
      min-height: 400px;

      table {
        border-collapse: collapse;
        background-color: $white-color;
        width: 100%;

        @include respond-to("tablet") {
          display: none;
        }

        th,
        td {
          padding: 10px 24px;
          font-size: 12px;
          text-align: left;
          min-width: 140px;
          max-width: 140px;

          &:first-child {
            min-width: 150px;
          }
        }

        tr {
          border-bottom: 1px solid #edf2f7;
        }

        thead {
          th {
            color: #696677;
          }
        }

        tbody {
          tr {
            &:last-child {
              border-bottom: none;
            }
          }

          td {
            color: $dark-color;
            padding: 12px 24px;
          }
        }
      }

      .mobile-table {
        background: $white-color;
        display: none;

        @include respond-to("tablet") {
          display: block;
        }

        &__row {
          padding: 10px 10px;
          border-bottom: 1px solid #ddd;
        }

        .row-item {
          display: flex;
          gap: 20px;
          justify-content: space-between;
          margin-bottom: 8px;

          &:last-child {
            margin-bottom: 0;
          }

          &__value {
            font-size: 12px;
          }

          &__title {
            margin-bottom: 0;
            font-weight: normal;
            font-size: 12px;
          }
        }
      }
    }
  }
}
