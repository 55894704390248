@import "../../../../assets/styles/base/variables";

.storeCustomerForm {
  &__bottom {
    position: absolute;
    bottom: 0px;
    padding: 20px 0;
    display: flex;
    width: 420px;
    background: $white-color;
    justify-content: space-between;
  }
}
