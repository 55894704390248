.recent-transactions-table {
    border-collapse: collapse;

    &__body {
        &__row {
            td {
                font-size: 14px;
                font-weight: 500;
            }

            td:not(:first-child) {
                padding: 14px 0 14px 14px;
            }
        }
    }
}
