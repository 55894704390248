@import "../../assets/styles/base/mixins";
@import "../../assets/styles/base/variables";

.customers-layout {
  &__header {
    display: flex;
    justify-content: space-between;
    @include respond-to("tablet") {
      flex-direction: column;
    }
  }
  &__buttons {
    display: flex;
    gap: 16px;
  }
  &__links {
    border-bottom: 1px solid #e8e9eb;
    margin-top: 25px;

    &__item {
      display: flex;
      align-items: center;
      transition: all 0.2s ease-in-out;
      font-size: 16px;
      text-decoration: none !important;
      padding: 0.5em 0;
      color: #696677;
      border-bottom: 2px solid transparent;
      min-width: fit-content;
      margin-right: 2rem;
      @include respond-to("tablet") {
        margin-right: 15px;
      }

      &.active {
        color: $purple-color;
        border-color: $purple-color;
      }
    }
  }
}
