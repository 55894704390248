.product-form {
    &__main {
        margin-top: 32px;
        display: flex;
        flex-direction: column;
        gap: 16px;

        &__item {
            text-decoration: none;
            display: flex;
            align-items: center;
            border: 1.5px solid #7647ee;
            border-radius: 8px;
            gap: 16px;
            padding: 16px;

            &:hover {
                text-decoration: none;
            }

            .icon {
                width: 50px;
                height: 50px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 8px;

                &.orange {
                    background-color: #ffeee9;
                }
                &.green {
                    background-color: #e8f7ee;
                }
            }
        }
    }
}
