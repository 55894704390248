@import "../../../../assets/styles/base/variables";
@import "../../../../assets/styles/base/mixins";

.customer {
    &__content {
        margin-top: 32px;

        span {
            font-size: 14px;
        }

        &__header {
            display: flex;
            align-items: center;
            justify-content: space-between;

            &__title {
                display: flex;
                align-items: center;
            }

            &__actions {
                display: flex;
                align-items: center;
                gap: 21.5px;
            }
        }

        &__details {
            background-color: $white-color;
            width: 100%;
            border-radius: 12px;
            padding: 24px;
            display: grid;
            gap: 24px;
            grid-template-columns: repeat(3, 1fr);
            @include respond-to("tablet") {
                grid-template-columns: repeat(2, 1fr);
                padding: 15px;
            }
        }

        &__transactions{
            @include respond-to("small") {
                display: none;
            }
        }
    }
}