@import "../../../../assets/styles/base/mixins";
@import "../../../../assets/styles/base/variables";

.create-sub-account {
  &__acct-widget {
    width: 500px;
    margin: 100px auto;
  }

  &__form-details {
    width: 730px;
  }
}
