@import "../../assets/styles/base/variables";
@import "../../assets/styles/base/mixins";

.tags-layout {
  padding-top: 3.5em;

  @include respond-to("tablet") {
    padding-top: 2em;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__tab {
    margin-top: 37px;
    margin-bottom: 2em;
    border-bottom: 1px solid #e8e9eb;
    display: flex;
    align-items: center;
    gap: 2em;

    &__item {
      display: flex;
      align-items: center;
      transition: all 0.2s ease-in-out;
      font-size: 16px;
      text-decoration: none !important;
      padding: 0.5em 0;
      color: #696677;
      // border-bottom: 2px solid transparent;
      min-width: fit-content;
      position: relative;

      &.active {
        &::before {
          content: "";
          display: block;
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 2px;
          background-color: $purple-color;
        }
        color: $purple-color;
        border-color: $purple-color;
      }
    }
  }
}
