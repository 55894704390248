@import "../../../../assets/styles/base/variables";

.api-keys-page {
  &__nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__content {
    margin: 2em 0;

    @media (max-width: 768px) {
      margin: 4em 0;
    }

    &__section {
      margin-bottom: 2.5em;

      &__title {
        font-size: 20px;
        font-weight: 500;
        color: $dark-color;
      }

      &__key {
        display: flex;
        align-items: center;
        height: 65px;
        background: #ffffff;
        border: 1px solid rgba(151, 151, 151, 0.4);
        border-radius: 12px;
        padding: 0 10px;
        justify-content: space-between;

        @media (max-width: 768px) {
          flex-direction: column;
          align-items: center;
          height: auto;
          width: 100%;
          padding: 1em;
          gap: 1em;
        }
      }
    }
  }
}
