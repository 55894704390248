@import "../../assets/styles/base/variables";
@import "../../assets/styles/base/mixins";

.dashboard-layout {
  display: flex;
  width: 100%;
  height: 100%;
  background: #f8f8f8;
  position: absolute;
  max-width: 1600px;
  margin: auto;
  overflow-y: auto;
  overflow-x: hidden;
  zoom: 95%;

  &__sidebar {
    min-width: 230px;
    overflow-y: auto;
    overflow-x: hidden;
    position: sticky;
    top: 0;

    .sidebar-section {
      max-width: 230px;
      width: 230px;
      background: $purple-color;
      min-height: 100%;
      display: flex;
      flex-direction: column;
      transition: 0.3s ease;

      @include respond-to("small") {
        margin-left: -230px;
        position: fixed;
      }

      &__tag {
        position: relative;
        margin: auto auto 10px auto;
        width: 90%;

        .user-profile {
          margin-bottom: 5px;
          background-color: $white-color;
          border-radius: 30px;
          display: flex;
          align-items: center;
          padding: 0.3em 5px;

          &__avatar {
            width: 40px;
            height: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            color: $purple-color;
            background-color: #d7cdfe;
            font-weight: bold;
            font-size: 20px;
          }

          .business-name {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            display: block;
            font-size: 14px;
          }
        }

        .account-dropdown {
          position: absolute;
          left: 0;
          width: 100%;
          background: $white-color;
          z-index: 3;
          border-radius: 8px;
          max-height: 0px;
          transition: 0.1s ease;
          overflow: hidden;

          &.active {
            max-height: 100px;
            padding: 10px;
          }
        }
      }

      &__logo {
        width: 75px;
        // height: 24px;
        margin: 25px 0 20px 1.5em;
      }

      &__content {
        width: 100%;
        height: 100%;

        &-links {
          display: flex;
          flex-direction: column;
          width: 100%;
          padding: 2em 0;

          .link-item {
            display: flex;
            align-items: center;
            padding: 1em 12px;
            margin: 0 12px;
            border-radius: 12px;

            &:hover {
              text-decoration: none;
            }

            &.active {
              background-color: rgba(30, 1, 111, 0.8);
            }

            span {
              color: white;
            }

            &.sub-tab {
              padding: 1em 12px 1em 30px;
            }

            .transform-icon {
              transition: 0.2s;

              &.active {
                transform: rotate(-180deg);
              }
            }
          }

          .sub-tab-body {
            max-height: 0;
            transition: 0.2s;
            overflow: hidden;

            &.active {
              max-height: 300px;
            }
          }

          .transfer-alert {
            background: $danger-color;
            color: $white-color;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            font-size: 12px;
            border-radius: 50%;
            min-width: 22px;
            min-height: 22px;
            max-width: 22px;
            max-height: 22px;
          }
        }

        hr {
          width: calc(100% - 64px);
          margin: auto;
          background-color: white;
        }
      }
    }

    @include respond-to("small") {
      width: 0;
      position: fixed;
      overflow-y: auto;
      overflow-x: hidden;
      z-index: 3000;

      &.active {
        .sidebar-overlay {
          height: 100%;
          width: 100%;
          opacity: 0.46;
          background-color: #212121;
          z-index: -1;
          position: fixed;
          top: 0;
        }

        .sidebar-section {
          margin-left: 0;
        }
      }
    }
  }

  &__content {
    .sidebar-toggler {
      display: none;
      justify-content: flex-end;

      @include respond-to("small") {
        display: flex;
      }
    }

    width: calc(100% - 230px);
    min-height: 100%;
    padding: 0 32px 50px 32px;
    overflow: auto;

    @include respond-to("small") {
      width: 100%;
    }

    @include respond-to("big-tablet") {
      padding: 0 5% 50px 5%;
    }
  }
}
