@import "../../../../assets/styles/base/variables";

.storeTransactiondetails {
  padding-bottom: 100px;

  p {
    font-size: 14px;
  }
  .orderDetails {
    display: flex;
    gap: 1%;
    justify-content: space-between;
    margin-bottom: 10px;
    p {
      overflow: hidden;
      width: 24%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  &__bottom {
    position: absolute;
    bottom: 0px;
    padding: 20px 0;
    display: flex;
    justify-content: space-between;
    gap: 20px;
    width: 420px;
    background: $white-color;
  }
}
