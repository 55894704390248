@import "../../assets/styles/base/variables";
@import "../../assets/styles/base/mixins";

.signup-layout {
  position: absolute;
  height: 100%;
  width: 100%;
  max-width: 1600px;
  margin: auto;
  display: flex;

  &__sidebar {
    min-width: 35%;
    max-width: 35%;
    background: $purple-color;
    height: 100%;
    position: static;
    top: 0;
    padding: 50px 50px;

    @include respond-to("large") {
      padding: 40px 3%;
    }
    @include respond-to("small") {
      display: none;
    }

    &__logo {
      width: 6rem;
    }

    &__image {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 13rem;
    }
  }

  &__content {
    width: -webkit-fill-available;
    min-height: 100%;
    padding: 50px 5% 50px 7%;
    background: #f3f1fc;
    overflow: auto;

    @include respond-to("large") {
      padding: 30px 40px;
    }

    @include respond-to("small") {
      width: 100%;
      padding: 30px 5%;
    }
  }
}
