.product-table-options {
  display: flex;
  flex-direction: column;
  position: relative;
  margin-left: 35px;

  button {
    background: none;
    outline: none;
    border: none;
  }

  ul {
    // display: none;
    padding: 0;
    position: absolute;
    top: 100%;
    bottom: 0;
    left: -140px;
    right: 0;
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    border-radius: 12px;
    background: #ffffff;
    box-shadow: 0px 20px 36px rgba(0, 0, 0, 0.05);
    z-index: 1000;
    max-width: 201px;
    max-height: fit-content;
    width: 201px;
    overflow: hidden;

    li {
      padding: 16px;
      font-size: 16px;
      cursor: pointer;
    }
  }
}
