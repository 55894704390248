@import "../../../assets/styles/base/variables";

.statement-of-account {
  width: 100%;
  height: 100%;
  position: fixed;
  right: 0;
  left: 0;
  margin: auto;
  bottom: 0;
  display: flex;
  justify-content: right;
  align-items: center;
  z-index: 1000;

  &__backdrop {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 1;
    overflow: hidden;
  }

  &__content {
    background-color: $white-color;
    height: 100%;
    overflow: auto;
    width: 498px;
    padding: 24px 40px;
    z-index: 100;

    .close-icon {
      margin-left: auto;
      display: block;
      cursor: pointer;
      font-size: 25px;
    }

    .statement-form {
      display: flex;
      flex-direction: column;
      gap: 15px;

      .daterange-section {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }
  }
}
