@import "../../../assets/styles/base/mixins";
@import "../../../assets/styles/base/variables";

.giftcard-form {
  margin-top: 30px;

  &__fields-group {
    display: flex;
    gap: 24px;
    @include respond-to("big-tablet") {
      gap: 0px;
      flex-wrap: wrap;
    }

    & > div {
      width: 50%;
      @include respond-to("big-tablet") {
        width: 100%;
      }
    }
  }

  &__action {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 48px;
  }
}
