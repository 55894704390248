.transaction-tag {
    padding: 16px 24px;

    &__list {
        margin: 24px 0;
        display: flex;
        flex-wrap: wrap;
        gap: 8px;

        &__item {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 10px 12px;
            background-color: #efefef;
            border-radius: 8px;
            font-size: 10px;
            color: #15112d;
        }
    }

    &__actions {
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: 24px;
    }

    &__form {
        display: flex;
        flex-direction: column;
        gap: 24px;
        margin-top: 24px;

        &__actions {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
    }
}
