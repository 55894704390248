@import "../../../assets/styles/base/variables";
@import "../../../assets/styles/base/mixins";

.product {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  background-color: $white-color;
  border-radius: 8px;
  padding: 8px;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 1px 4px;
  border: 1px solid rgba(0, 0, 0, 0.15);

  .product-image {
    width: 40px;
    height: 40px;
    flex-shrink: 0;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  .product-details {
    flex: auto;

    .product-price {
      font-size: 12px;
    }

    .product-name {
      font-size: 14px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      max-width: 250px;
    }

    .product-quantity {
      font-size: 12px;
    }
  }

  &-order {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 45px;
  }

  &-actions {
    display: flex;
    gap: 8px;
    align-items: center;

    .increment,
    .decrement,
    .count,
    .delete {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 24px;
      height: 24px;
      border-radius: 4px;
      border: none;
      outline: none;
      font-size: 12px;
    }

    .increment {
      background-color: $purple-color;
      color: $white-color;
    }

    .decrement {
      background-color: $white-color;
      border: 1px solid #7647ee;
      color: $purple-color;
    }

    .count {
      background-color: #f2edfe;
    }

    .delete {
      color: $danger-color;
      border: 1px solid $danger-color;
    }
  }
}
