// Edit this section an set color variable with occurdance with the design
$main--bg: #e5e5e5;
$purple-color: #7647ee;
$dark-purple-color: #1e016f;
$white-color: #ffffff;
$dark-color: #15112d;
$gray-color: #9091b3;
$light-gray-color: #91919f;
$danger-color: #dc3545;
$lumi-purple: #F2EDFE;

.white {
    background: $white-color !important;
    &--text {
        color: $white-color !important;
    }
}
.purple {
    background: $purple-color !important;
    &--text {
        color: $purple-color !important;
    }
}
.gray {
    background: $light-gray-color;
    &--text {
        color: $gray-color;
    }
}
.dark {
    background: $dark-color;
    &--text {
        color: $dark-color;
    }
}
.error {
    background: $danger-color;
    &--text {
        color: $danger-color;
    }
}

$shadow: 0px 4px 20px rgba(135, 126, 144, 0.15);

$purple-border: 1px solid #766df4;
$light-border: 1px solid rgba(255, 255, 255, 0.5);
$input-border: 0.5px solid #979797;
