@import "../../../assets/styles/base/variables";
@import "../../../assets/styles/base/mixins";

.storeCart {
  background-color: $white-color;
  border-radius: 8px;
  padding: 16px 16px 70px 16px;
  height: 100%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;

  &__items {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-bottom: 16px;
  }

  .priceSummary {
    border-radius: 8px;
    border: 1px solid #ddd;
    padding: 13px 12px 0 12px;
    margin-bottom: 12px;
    margin-top: auto;
    &__row {
      display: flex;
      gap: 10px;
    }
  }

  &__bottom {
    position: absolute;
    bottom: 0;
    background: $white-color;
    width: 100%;
    left: 0;
    padding: 16px;
  }
}
