@import "../../../assets/styles/base/variables";
@import "../../../assets/styles/base/mixins";

.store-checkout {
  padding: 20px 0;

  &__item-search {
    border-radius: 8px;
    overflow: hidden;
    display: flex;
    align-items: center;
    background-color: #f3f1fc;
    padding: 0 8px;

    input {
      width: 100%;
      padding: 8px;
      outline: none;
      border: none;
      background-color: transparent;
    }
  }

  &__purchase-options {
    display: flex;
    gap: 16px;
    width: 100%;
    margin: 16px 0;
  }

  &__purchase-option {
    border: 1px solid rgba(0, 0, 0, 0.1);
    width: 100%;
    border-radius: 12px;

    .wallet__option {
      justify-content: center;
      padding: 16px;
      box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    }
  }
}
