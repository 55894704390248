@import "../../../assets/styles/base/variables";

.cart-items-confirmation {
  display: flex;
  flex-direction: column;
  // height: 100%;
  height: 730px;

  &-close {
    justify-content: center;
    align-self: flex-end;
    cursor: pointer;

    & > img {
      height: 25px;
      width: 25px;
    }
  }

  &-details-wrapper {
    margin-top: 24px;
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  &-details__title {
    margin-bottom: 32px;
  }

  &-submission {
    margin-top: auto;
  }
}
