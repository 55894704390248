@import "../../../assets/styles/base/variables";
@import "../../../assets/styles/base/mixins";

.productCard {
  display: flex;
  flex-direction: column;
  gap: 4px;
  cursor: pointer;

  &__top,
  &__bottom {
    padding: 10px;
    border-radius: 8px;
    background-color: $white-color;
    &.active {
      border: 1px solid $purple-color;
    }
  }

  &__top {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__bottom {
    padding: 14px 0px;
    display: flex;
    gap: 10px;
    flex-direction: column;
  }

  .left {
    display: flex;
    align-items: center;
    gap: 8px;
    width: 200px;

    .productImage {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 8px;
      width: 40px;
      height: 40px;

      img {
        width: 100%;
        object-fit: cover;
      }
    }
    p {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
  .middle {
    display: flex;
    align-items: center;
    gap: 24px;
    width: 400px;

    p {
      font-size: 13px;
      flex-shrink: 0;
      min-width: 120px;
      text-align: left;

      &.stock {
        background-color: #19ae57;
        color: $white-color;
        display: flex;
        align-items: center;
        gap: 5px;
        padding: 5px 10px;
        border-radius: 16px;
        justify-content: center;
        &.lowQuantity {
          background-color: #ed0000;
        }
      }
    }
  }

  .right {
    display: flex;
    justify-content: flex-end;
    gap: 20px;
    width: 200px;
  }

  .variantHeader {
    border-bottom: 1px solid #ddd;
    padding-left: 10px;
    margin-bottom: 0;
    padding-bottom: 5px;
  }

  .productSubCard {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    border-bottom: 1px solid #ddd;
    padding: 0 10px 8px 10px;

    &:last-child {
      border: none;
      padding-bottom: 0;
    }
  }

  .product__actions {
    display: flex;
    align-items: center;
    gap: 8px;

    button {
      outline: none;
      background: none;
      border: none;
      width: 25px;
      height: 25px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      border-radius: 4px;

      &.increment {
        background-color: $purple-color;
        color: white;
      }

      &.decrement {
        background-color: $white-color;
        color: $purple-color;
        border: 1px solid $purple-color;
      }
    }

    .count {
      width: 25px;
      height: 25px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      background-color: #f2edfe;
    }
  }

  .viewBtn,
  .addToCartBtn {
    padding: 10px 16px;
    height: 35px;
    font-size: 12px;
    width: 110px;
  }
}
