@import "../../../assets/styles/base/variables";
@import "../../../assets/styles/base/mixins";

.giftcard-item {
  display: flex;
  flex-direction: column;

  &__container {
    border: 0.75px solid #ad91f5;
    background: #f3f1fc;
    border-radius: 15.2772px;
    padding: 32px 24px 12px;
    position: relative;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 24px;

    .giftcard-icon {
      width: 76px;
      height: 76px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      border: 0.75px solid #ad91f5;
      background-color: #9f93bc;
      overflow: hidden;

      img {
        max-width: 100%;
        height: 100%;
        width: 100%;
        display: block;
        object-fit: cover;
      }
    }

    .custom-switch {
      position: absolute;
      left: 282px;
      top: 30px;
    }

    &__points {
      background: #ffffff;
      border-radius: 4px;
      display: flex;
      align-items: center;
      max-width: fit-content;

      span {
        font-size: 12px;
        padding: 10px;
        font-weight: 500;

        &:first-child {
          background-color: $purple-color;
          color: $white-color;
          border-top-left-radius: 4px;
          border-bottom-left-radius: 4px;
        }
      }
    }
  }
}
