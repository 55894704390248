@import "../../../../assets/styles/base/variables";
@import "../../../../assets/styles/base/mixins";

.transaction {
  &__content {
    margin-top: 32px;

    span {
      font-size: 14px;
    }

    &__header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 10px;
      flex-wrap: wrap;

      &__title {
        display: flex;
        align-items: center;
      }
    }

    &__details {
      background-color: $white-color;
      border-radius: 12px;
      padding: 24px;
      margin-top: 10px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      gap: 24px;
      @include respond-to("tablet") {
        flex-direction: column;
        padding: 15px;
      }

      &__left {
        flex: 0.95;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 24px;
        @include respond-to("small") {
          grid-template-columns: repeat(2, 1fr);
        }
        @include respond-to("tablet") {
          grid-template-columns: repeat(2, 1fr);
          order: 2;
        }
      }

      &__right {
        @include respond-to("tablet") {
          margin-left: auto;
          order: 1;
        }
      }
    }

    &__customer-details {
      margin-top: 24px;

      &__content {
        background-color: $white-color;
        width: 100%;
        padding: 24px;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        gap: 24px;
        @include respond-to("tablet") {
          padding: 15px;
        }
      }
    }

    &__other-information {
      margin-top: 24px;

      &__content {
        background-color: $white-color;
        width: 100%;
        padding: 24px;
        display: flex;
        gap: 50px;
        @include respond-to("tablet") {
          padding: 15px;
        }
      }
    }
  }
}
