.revenue-analytics {
    background-color: #fff;
    padding: 24px;
    border-radius: 8px;
    border: 1px solid #f3f1fc;

    &__chart {
        margin-top: 24px;
        display: flex;
        align-items: center;
        overflow: hidden;
    }

    &__total {
        position: absolute;
        top: 42%;
        left: 50%;
        transform: translateX(-50%);
        text-align: center;
    }

    &__legend {
        display: flex;
        flex-wrap: wrap;
        gap: 24px;
        &-item {
            display: flex;
            align-items: center;

            .indicator {
                width: 4px;
                height: 28px;
                display: block;
                background-color: #696677;
                border-radius: 5px;
            }

            .title {
                font-size: 14px;
                color: #696677;
                margin-right: 35px;
            }
        }
    }
}