@import "../../../assets/styles/base/variables";
@import "../../../assets/styles/base/mixins";

.sales-transactions-table {
  margin-top: 32px;
  background-color: $white-color;
  padding: 24px;
  width: 100%;

  &__header {
    display: flex;
    gap: 16px;
    margin-bottom: 24px;

    .search-section {
      width: 700px;

      @include respond-to("large") {
        width: 100%;
      }
    }
  }

  &__content {
    display: block;
    width: 100%;
    overflow-x: auto;
    min-height: 400px;

    table {
      border-collapse: collapse;
      background-color: $white-color;
      width: 100%;
      max-width: 100%;

      @include respond-to("large") {
        display: none;
      }

      th,
      td {
        padding: 10px 0px;
        font-size: 12px;
        text-align: left;
        min-width: 150px;
        max-width: 140px;

        &:first-child {
          max-width: 150px;
        }
        &:last-child {
          padding-right: 24px;
        }
      }

      tr {
        border-bottom: 1px solid #edf2f7;
      }

      thead {
        th {
          color: #696677;
        }
      }

      tbody {
        tr {
          position: relative;

          &:last-child {
            border-bottom: none;
          }
        }

        td {
          color: $dark-color;
          padding: 12px 0;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;

          // &:last-child {
          //     overflow: visible;
          // }
        }
      }
    }

    &-mobile {
      display: none;

      @include respond-to("large") {
        display: flex;
        flex-direction: column;
        gap: 20px;
      }

      &-card {
        display: flex;
        flex-direction: column;
        gap: 10px;
        border-radius: 8px;
        padding: 12px;
        background-color: $white-color;
        border: 1px solid #ddd;

        &__title {
          display: flex;
          gap: 40px;
          justify-content: space-between;
          align-items: center;
        }

        .status {
          color: #19ae57;
          background-color: #e6fff0;
          border-radius: 8px;
          padding: 5px 16px;
        }
      }
    }
  }
}
