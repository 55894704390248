@import "../../../assets/styles/base/variables";
@import "../../../assets/styles/base/mixins";

.modal {
  width: 100%;
  height: 100%;
  position: fixed;
  right: 0;
  left: 0;
  margin: auto;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;

  @include respond-to("tablet") {
    align-items: flex-end;
  }
  &__container {
    background-color: $white-color;
    border-radius: 10px;
    position: relative;
    max-height: 95%;
    overflow: auto;
    padding: 15px 15px 25px 15px;
    @include respond-to("tablet") {
      border-radius: 20px;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      width: 100% !important;
      padding: 25px 15px 25px 15px;
    }
    &__default-header {
      display: flex;
      justify-content: flex-end;
      margin-bottom: 15px;
    }
    .close-icon {
      cursor: pointer;
    }
  }
  &__backdrop {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: -1;
  }
}
