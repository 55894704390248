@import "../../../assets/styles/base/variables";

.image-upload {
  min-height: 300px;

  .upload-progress {
    height: 10px;
    background: transparent;
    position: absolute;
    width: 100%;
    left: 0;
    top: 0;
    border-radius: 8px;

    &.active {
      background: rgb(243, 241, 252);
    }

    &__loader {
      height: 100%;
      background: #f7ad3c;
      transition: 0.3s ease;
      border-radius: 8px;
    }
  }

  &__container {
    padding: 15px 30px;

    &__items {
      display: flex;
      gap: 0.8%;
      flex-wrap: wrap;
      margin-top: 35px;

      .image-item {
        width: 13.5%;
        min-width: 90px;
        height: 90px;
        border: 1px solid #ddd;
        cursor: pointer;
        margin-bottom: 5px;

        img {
          width: 100%;
          height: 100%;
        }

        &:hover {
          border-color: $purple-color;
        }
      }
    }
  }
}
