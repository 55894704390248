.webhooks-page {
    &__nav {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .btn__save-webhooks {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 99px;
            font-weight: bold;
        }
    }

    &__content {
        margin: 2em 0;

        @media (max-width: 768px) {
            margin: 3em 0;
        }

        &__section {
            margin-bottom: 2.5em;

            &__key {
                display: flex;
                align-items: center;
                height: 65px;
                background: #ffffff;
                border: 1px solid rgba(151, 151, 151, 0.4);
                border-radius: 12px;
                padding: 0 10px;

                @media (max-width: 768px) {
                    flex-direction: column;
                    align-items: center;
                    height: auto;
                    width: 100%;
                    gap: 1em;
                }

                &__label {
                    font-weight: bold;
                }
            }
        }
    }
}
